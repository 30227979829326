"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.admin = exports.client = exports.server = void 0;
var tslib_1 = require("tslib");
var Preact = tslib_1.__importStar(require("preact"));
var hooks_1 = require("preact/hooks");
var Hydration_1 = require("../Hydration");
require("./ImageView.sass");
function getStyles(props) {
    var wrapperStyles = Object.assign({}, props.style);
    wrapperStyles.maxWidth = Number.isInteger(props.width) ? props.width + 'px' : props.width;
    wrapperStyles.maxHeight = Number.isInteger(props.height) ? props.height + 'px' : props.height;
    if (props.aspect) {
        wrapperStyles.height = 0;
        wrapperStyles.paddingBottom = props.aspect + '%';
    }
    var accessStyles = {};
    if (props.protect) {
        accessStyles.pointerEvents = 'none';
        accessStyles.userSelect = 'none';
    }
    var imageStyles = Object.assign({}, props.imgStyle);
    if (props.aspect)
        imageStyles.position = 'absolute';
    return { wrapperStyles: wrapperStyles, imageStyles: imageStyles, accessStyles: accessStyles };
}
function ImageView(props) {
    var _a, _b, _c;
    var ref = hooks_1.useRef(null);
    var _d = hooks_1.useState('INITIAL'), state = _d[0], setState = _d[1];
    var _e = hooks_1.useState(false), lightbox = _e[0], setLightbox = _e[1];
    hooks_1.useEffect(function () {
        if (state !== 'INITIAL')
            return;
        var img = ref.current.querySelector('img');
        if (img.complete)
            return setState('COMPLETE');
        setState('AWAITING_OBSERVATION');
        var completeCb = function () { return setState('COMPLETE'); };
        var observer = new IntersectionObserver(function (_a) {
            var observing = _a[0];
            if (observing.intersectionRatio > 0) {
                if (img.complete)
                    setState('COMPLETE');
                else
                    img.addEventListener('load', completeCb);
                observer.disconnect();
            }
            ;
        }, { threshold: 0, rootMargin: '0px 0px 1000px 0px' });
        observer.observe(ref.current);
    }, [props.media, state]);
    var src = (_a = props.media) === null || _a === void 0 ? void 0 : _a.publicPath;
    var _f = getStyles(props), wrapperStyles = _f.wrapperStyles, imageStyles = _f.imageStyles, accessStyles = _f.accessStyles;
    return (Preact.h(Preact.Fragment, null,
        Preact.h("div", { style: wrapperStyles, ref: ref, class: ('ImageView ' + ((_b = props.class) !== null && _b !== void 0 ? _b : '')).trim(), onClick: props.lightbox ? function () { return setLightbox(true); } : undefined },
            Preact.h("picture", null,
                state !== 'AWAITING_OBSERVATION' && Preact.h("source", { srcset: src }),
                Preact.h("img", { style: Object.assign({}, imageStyles, accessStyles), src: src + '?res=preload', alt: props.alt || '', loading: state === 'INITIAL' ? 'lazy' : undefined }))),
        lightbox && Preact.h("div", { onClick: function () { return setLightbox(false); }, class: ('ImageView-Modal ' + ((_c = props.class) !== null && _c !== void 0 ? _c : '')).trim() },
            Preact.h("img", { style: accessStyles, src: src, alt: props.alt || '' }))));
}
var HydratedImageView = Hydration_1.withHydration('ImageView', ImageView, function (props) {
    var _a;
    props.media = { publicPath: (_a = props.media) === null || _a === void 0 ? void 0 : _a.publicPath };
    return props;
});
exports.server = {
    identifier: 'ImageView',
    element: HydratedImageView,
    config: {
        props: {
            media: { name: 'Image Source', type: ['media:image'] },
            alt: { name: 'Alt Text', type: 'text', optional: true },
            aspect: { name: 'Aspect Ratio', type: ['number'], optional: true },
            lightbox: { name: 'Open Lightbox on Click', type: 'boolean', default: true },
            protect: { name: 'Copy Protection', type: 'boolean' }
        }
    }
};
exports.client = {
    identifier: 'ImageView',
    element: ImageView
};
exports.admin = tslib_1.__assign(tslib_1.__assign({}, exports.server), exports.client);
