"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Hydration_1 = require("./Hydration");
var Calendar_1 = require("./elements/Calendar");
var ImageView_1 = require("./elements/ImageView");
(function () {
    [Calendar_1.client,
        ImageView_1.client
    ].forEach(Hydration_1.hydrate);
})();
