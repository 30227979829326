"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hydrate = exports.withHydration = void 0;
var tslib_1 = require("tslib");
var Preact = tslib_1.__importStar(require("preact"));
function withHydration(identifier, Element, mutateProps) {
    return function (props) {
        var safeProps = JSON.parse(JSON.stringify(tslib_1.__assign(tslib_1.__assign({}, props), { children: undefined })));
        if (mutateProps)
            safeProps = mutateProps(safeProps);
        return (Preact.h(Preact.Fragment, null,
            Preact.h("script", { type: "application/hydrate", "data-element": identifier, dangerouslySetInnerHTML: { __html: JSON.stringify(safeProps) } }),
            Preact.h(Element, tslib_1.__assign({}, props))));
    };
}
exports.withHydration = withHydration;
function hydrate(e) {
    document.querySelectorAll("script[type=\"application/hydrate\"][data-element=\"" + e.identifier + "\"]").forEach(function (tag) {
        var props = JSON.parse(tag.innerText);
        Preact.render(Preact.createElement(e.element, props), tag.parentElement, tag.nextElementSibling);
        tag.remove();
    });
}
exports.hydrate = hydrate;
