"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var Preact = tslib_1.__importStar(require("preact"));
var hooks_1 = require("preact/hooks");
require("./CalendarEvent.sass");
var ImageView_1 = require("../ImageView");
function CalendarEvent(_a) {
    var _b;
    var event = _a.event, onClose = _a.onClose;
    var _c = hooks_1.useState(0), media = _c[0], setMedia = _c[1];
    hooks_1.useEffect(function () {
        document.body.style.overflow = 'hidden';
        return function () { return document.body.style.overflow = ''; };
    });
    return (Preact.h("div", { class: 'CalendarEvent', onClick: onClose },
        Preact.h("div", { class: 'CalendarEvent-Modal' },
            Preact.h("div", { class: 'CalendarEvent-Wrap', onClick: function (e) { return e.stopPropagation(); } },
                event.media && Preact.h("div", { class: 'CalendarEvent-Media' },
                    Preact.h(ImageView_1.client.element, { lightbox: true, protect: true, media: event.media[media], style: { objectPosition: 'center ' + ((_b = event.mediaAttachment) !== null && _b !== void 0 ? _b : 'center') } }),
                    event.media.length > 1 && Preact.h("button", { class: 'CalendarEvent-MediaNav Left', "aria-label": 'Navigate Left', onClick: function () { return setMedia((media - 1) < 0 ? (event.media.length - 1) : (media - 1)); } },
                        Preact.h("svg", { xmlns: 'http://www.w3.org/2000/svg', height: '24', viewBox: '0 0 24 24', width: '24' },
                            Preact.h("path", { d: 'M0 0h24v24H0V0z', fill: 'none', opacity: '.87' }),
                            Preact.h("path", { fill: 'white', d: 'M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0'
                                    + ' 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z' }))),
                    event.media.length > 1 && Preact.h("button", { class: 'CalendarEvent-MediaNav Right', "aria-label": 'Navigate Right', onClick: function () { return setMedia((media + 1) % event.media.length); } },
                        Preact.h("svg", { xmlns: 'http://www.w3.org/2000/svg', height: '24', viewBox: '0 0 24 24', width: '24' },
                            Preact.h("path", { d: 'M24 24H0V0h24v24z', fill: 'none', opacity: '.87' }),
                            Preact.h("path", { fill: 'white', d: 'M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15'
                                    + ' 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z' })))),
                Preact.h("div", { class: 'CalendarEvent-Content' },
                    Preact.h("h4", { class: 'CalendarEvent-Title' }, event.name),
                    Preact.h("h5", { class: 'CalendarEvent-Date' }, event.hideDate ? 'Date to be determined.' : event.dateString),
                    Preact.h("div", { class: 'CalendarEvent-Body', dangerouslySetInnerHTML: { __html: event.description } }))))));
}
exports.default = CalendarEvent;
